import { IsAuthenticatedResponse } from 'api'

/**
 * Stores list of roles from the auhtentication api and maps them to easy to use
 * functions (instead of matching the specific non-machine formatted names)
 *
 * Attempt to create function names as follows for consistency:
 * - start with 'can'
 * - Menu based roles (e.g., see canViewApp) = canViewX
 * - Edit and create normally go together so prefer canEditX unles the role explicitly splits them
 */
export class Authorization {
  private rolesMap: { [key: string]: boolean } = {}

  constructor(roles: IsAuthenticatedResponse['roles']) {
    roles.map((r) => (this.rolesMap[r] = true))
  }

  private getRole(role: string) {
    return this.rolesMap[role] ?? false // avoid returning undefined
  }

  get canSelectExtendedEffectiveDateRanges() {
    return this.getRole('Employment Change - Can Select Extended Date Ranges')
  }

  get canViewPractitionerChangeReasons() {
    return this.getRole(
      'Employment Change - Can View Practitioner Change Reasons'
    )
  }

  get canViewPractitionerActionBoard() {
    return this.getRole('Menu - Payroll - Practitioner Action Board')
  }

  get canViewHrActionBoard() {
    return this.getRole('Menu - HR - HR Action Board')
  }

  get canViewTrainingActionBoard() {
    return this.getRole('Menu - Operations - Training Action Board')
  }

  /**
   * MENU ACCESS ROLES
   */
  get canViewApp() {
    return this.getRole('Menu - WKS Connect 2.0')
  }

  get canViewHrAndFaqLinks() {
    return this.getRole('Menu - WKS Connect 2.0 - HR & FAQ Links')
  }

  get canViewEmploymentChange() {
    return this.getRole('Menu - Employment Change')
  }

  get canViewPayroll() {
    return this.getRole('Menu - Payroll')
  }

  get canViewAccounting() {
    return this.getRole('Menu - Accounting')
  }

  get canViewOperations() {
    return this.getRole('Menu - Operations')
  }

  get statusChangeListDefaultToAllRequestsPage() {
    return (
      this.getRole(
        'Menu - Employment Change List - Default to All Requests Page'
      ) && this.canViewEmploymentChange
    )
  }

  get statusChangeListDefaultToPendingMyApprovalPage() {
    return (
      !this.statusChangeListDefaultToAllRequestsPage &&
      this.canViewEmploymentChange
    )
  }

  get canViewPayrollTimepunch() {
    return this.getRole('Menu - Payroll - Timepunch')
  }

  get canViewEasiHomeBorrowCoDeptWithoutRate() {
    return this.getRole('Easi - Can View Home/Borrow CoDept Without Rate')
  }

  get canViewTimepunchAudit() {
    return this.getRole('Timepunch - Can Upload CSVs for Audit')
  }

  get canViewPayrollTimePunchExternalImports() {
    return this.getRole('Menu - Payroll - TimePunch - External Imports')
  }

  get canViewTimePunchADPExportAndUpdateMappings() {
    return this.getRole('TimePunch - Can Export ADP and Update POS Mappings')
  }

  get canSelectTimePunchExtendedDateRanges() {
    return this.getRole('TimePunch - Can Select Extended Date Ranges')
  }

  get canViewTimePunchPosReports() {
    return this.getRole('TimePunch - Can View POS Reports')
  }

  get canEditTimePunch() {
    return this.getRole('TimePunch - Can Edit')
  }

  get canDownloadTimePunch() {
    return this.getRole('TimePunch - Can Download CSV Export')
  }

  get canTimePunchSelectMultipleEmployees() {
    return this.getRole('TimePunch - Can Select Multiple Employees')
  }

  get canViewIT() {
    return this.getRole('Menu - IT')
  }

  /**
   * EMPLOYEE ROLES
   */
  get canCompleteManuallyInPOS() {
    return this.getRole('Employment Change - Can Complete Manually in POS')
  }

  get canViewEmployeeSensitive() {
    return this.getRole('Employee - Can View Sensitive Fields')
  }

  get canViewEmployeeOwnDirectReports() {
    return this.getRole('Employee - Can View My Own and My Direct Reports')
  }

  get canViewEmployeeLimited() {
    return this.getRole('Employee - Can View Limited')
  }

  get canImpersonate() {
    return this.getRole('Employment Change - Can Impersonate')
  }

  get canDownload() {
    return (
      this.getRole('Employment Change - Can Complete All Requests') ||
      this.getRole(
        'Employment Change - Can Complete And Edit Leave of Absence Requests'
      )
    )
  }

  get canChangeApprover() {
    return this.getRole('Employment Change - Can Change Approver')
  }

  get canExportRequestList() {
    return this.getRole('Employment Change - Can Export Request List')
  }

  get canExportRosterCorrectionFile() {
    return this.getRole('Menu - Payroll - ADP and TalentReef Roster Correction')
  }

  get canCompleteAllTypes() {
    return this.getRole(
      'Employment Change - Can Complete and Edit All Request Types'
    )
  }

  get canEditLOA() {
    return this.getRole(
      'Employment Change - Can Complete And Edit Leave of Absence Requests'
    )
  }

  get canViewStoreAlignments() {
    return this.getRole('Menu - Store Alignment')
  }

  get canViewStoreAlignmentsAllFranchisors() {
    return this.getRole('Store Alignment - Can View All Franchisors')
  }

  get canViewStoreAlignmentsHomeBorrow() {
    return this.getRole('Store Alignment - Can View Home/Borrow')
  }

  get canEditStoreAlignments() {
    return this.getRole('Store Alignment - Can Edit')
  }

  get accountingBasicAccess() {
    return this.getRole('Accounting - 2.0 Basic Access')
  }

  get canViewZoomSync() {
    return this.getRole('Menu - IT - Zoom Sync')
  }

  get canViewGoogleDirectorySync() {
    return this.getRole('Menu - IT - Google Directory')
  }

  get canViewTools() {
    return this.getRole('Menu - Tools')
  }

  get canViewPayrollAdpPdfProcessor() {
    return this.getRole('Menu - Payroll - ADP PDF Processor')
  }

  get canViewCreateBorrow() {
    return this.getRole('Employment Change - Can Create Borrow')
  }

  get canViewRiskMgmt() {
    return this.getRole('Menu - Risk Mgmt')
  }

  get canViewHR() {
    return this.getRole('Menu - HR')
  }

  get canViewCreatePOSJobCode() {
    return this.getRole('Employment Change - Can Create POS Job Code Request')
  }

  get canViewCreateRehire() {
    return this.getRole('Employment Change - Can Create Rehire')
  }

  get canCreateTransfer() {
    return this.getRole('Employment Change - Can Create Transfer')
  }

  get canCreateEditAndBasic() {
    return this.getRole('Employment Change - Can Create and Edit Basic')
  }

  get canViewHrEsign() {
    return this.getRole('Menu - HR - eSign')
  }

  get canViewHrEsignList() {
    return this.getRole('Menu - HR - eSign List')
  }

  get canViewHrLinkToEsignDocuments() {
    return this.getRole(
      'HR - eSign - Can View All Statuses and Export Docs and CSVs'
    )
  }

  get canViewAccountingArKkdKorberInvoices() {
    return this.getRole('Accounting - AR - KKD Korber Invoices')
  }

  get canCreateAndEditInvoices() {
    return this.getRole('Invoice - Can Create and Edit')
  }

  get canViewMenuHREmployee() {
    return this.getRole('Menu - HR - Employee')
  }

  get canCreateAndViewOfferLetters() {
    return (
      this.getRole('Offer Letter - Can Create and View') ||
      this.getRole('Offer Letter - Can Create and View All')
    )
  }
}
